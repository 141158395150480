.uploadWrapper {
  display: flex;
  flex-direction: column;
}

.dropzoneBase {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.dropzoneFocused {
  border-color: #2196f3;
}

.dropzoneAccept {
  border-color: #00e676;
}

.dropzoneReject {
  border-color: #ff1744;
}

.uploadButtonWrapper {
  margin: 1rem 0;
  display: flex;
  justify-content: space-around;
}

.uploadInputText {
  font-size: 0.85rem;
}

.noMargin {
  margin: 0;
}

.fileListHeading {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.6;
  text-align: center;
  margin: 0.5rem 0;
}

.fileList {
  list-style: none;
  max-height: 200px;
  overflow-y: auto;
}

.fileListItem {
  /* display: grid; */
  align-items: inherit !important;
  /* grid-template-columns: auto 1fr auto;
  grid-template-rows: 1fr; */
  /* gap: 0.5rem; */
  flex-direction: column;
}

.fileListItem:hover {
  font-weight: bold;
}

.fileListItemName {
  margin: 0;
}

.errorMessageWrapper {
  display: flex;
  align-items: baseline;
  gap: 0.5rem;
}

.errorMessageStyle {
  color: red;
}

.gridContainer {
  display: grid;
  align-items: center;
  width: 100%;
  grid-template-columns: auto 1fr auto;
  gap: 1rem;
}

.hiddenInput {
  display: none;
}

.fileListItemName {
  margin: 0;
}

.flexBox {
  display: flex;
  align-items: center;
}

.flexBoxFullWidth {
  display: flex;
  width: 100%;
  margin-right: 1rem;
}

.minWidthBox {
  min-width: 35px;
}