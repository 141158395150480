.tableWrapper {
  /* display: flex;
  flex-direction: column;
  align-items: flex-start; */
  display: grid;
  grid-template-rows: 1fr 1fr 6fr 0.5fr;
}
.formEditorButtonWrapper {
  display: flex;
  margin: 0.5rem 0 3.5rem;
  width: 100%;
  justify-content: space-around;
}
.formEditor_topInputWrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.formEditor_formNameWrapper {
  display: flex;
  flex-direction: column;
  width: 65%;
}
.formEditorSaveButton {
  background-color: var(--background2) !important;
}
.formEditorSaveButton:hover {
  background-color: #7b1fa2 !important;
}

/* Needed for DND-Component START*/
.item {
  box-sizing: border-box;
  border: 0.1rem solid #bcbcbc;
  border-radius:0.75rem;
  user-select: none;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
}
.item.is-dragging {
  border: solid lightgray;
  border-radius: 8px;
}
/* Needed for DND-Component END*/
