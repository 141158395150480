.contentSection {
    display: flex;
    align-items: center;
}

.sectionHeading {
    font-weight: bold;
    font-size: 1.25rem;
    margin: 0 0 0 1rem;
    color: var(--black);
}

.dashboardNavlink {
    text-decoration: none;
    font-size: 1.5rem;
    white-space: nowrap;
    color: var(--black);
}

.dashboardNavlink:hover {
    color: var(--black);
}

.dashboardNavlinkList {
    text-decoration: none;
    font-size: 1.5rem;
    color: var(--black);
}

.dashboardNavlinkList:hover {
    color: var(--black);
}

.completedExaminationWrapper {
    display: flex;
    justify-content: space-between;
    font-size: 1rem;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
}

.completedExaminationsStringWrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.dashboardParagraph {
    margin: 0 0.5rem 0 0;
}

.completedExaminationsSpan {
    float: right;
    position: relative;
    right: 1rem;
}

.noMargin {
    margin: 0;
}

.marginLeft {
    margin-left: 1rem !important;
}

.noMarginTop {
    margin-top: 0;
}

.alignCenter {
    align-items: center !important;
}

.bold {
    font-weight: bold;
}

.secondHeading {
    font-weight: bold;
    font-size: 1.25rem;

}

.registeredWorkerSection {
    width: 100%;
}

.contactLink {
    font-size: 1rem;
    display: flex;
    align-items: center;
    color: var(--black);
    text-decoration: none;
}

.progressBarWrapper {
    padding: 0 0.5rem;
}

.progressBarText {
    float: right !important;
    padding: 0.5rem !important;
}

.registeredWorkersText {
    float: right !important;
    font-weight: bold !important;
}

.fullWidth {
    width: 100% !important;
}

.countListItem {
    display: flex;
    justify-content: space-between;
    font-size: 1rem;
    padding: 0.5rem 0 0.5rem 0.5rem !important;
    width: 100%;
}

.countListItemText {
    max-width: fit-content !important;
    word-break: break-all !important;
}

.contactText {
    align-items: center !important;
    justify-content: center !important;
    margin-left: 1rem !important;
    flex-direction: column !important;
}

.requestIconWrapper {
    margin-right: 0.5rem !important;
}

/* Media Queries */
/* For mobile phones: */
@media (max-width: 600px) {
    .requestIconWrapper {
        margin-right: 0 !important;
    }
}