.container {
    max-width: 50em !important;
    align-content: start !important;
    margin-left: auto !important;
    margin-right: auto !important;
}

.heading {
    display: flex;
    flex-direction: column;
}

.buttonTitle {
    display: flex;
    gap: 1rem;
}

.linkNoUnderline {
    color: #8c1ec8;
    text-decoration: none;
}

.linkUnderlined {
    color: #8c1ec8;
    text-decoration: underline;
}

.dropDown__executedBy {
    position: relative !important;
    z-index: 10 !important;
}