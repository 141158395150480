.container {
    justify-content: space-between !important;
    align-items: end !important;
}

.actionButtonsContainer {
    justify-content: space-between;
}

.statusContainer {
    column-gap: 1rem;
    justify-content: space-evenly;
    align-items: end;
}

.statusText {
    display: flex;
    align-items: baseline;
    margin: 0 !important;
    gap: 0.25rem;
}

.primaryText {
    font-size: 0.875rem !important;
    line-height: 1.43 !important;
    font-weight: 400 !important;
    color: rgba(0, 0, 0, 0.6) !important;
}

.secondaryText {
    font-weight: 700 !important;
    font-size: 1rem !important;
    color: #000000 !important;
}

.link {
    color: #8c1ec8;
    cursor: pointer;
    text-decoration: none;
}