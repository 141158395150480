.tagLabel {
    margin-bottom: 0;
    max-width: 400px;
    width: 100%;
}

.tagList {
    max-width: 400px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 0 !important;
}