.noPadding {
    padding: 0 !important;
}

.primaryText {
    font-size: 0.875rem !important;
    line-height: 1.43 !important;
    font-weight: 400 !important;
    color: rgba(0, 0, 0, 0.6) !important;
}

.secondaryText {
    font-weight: 600 !important;
    font-size: 1rem !important;
    color: #8c1ec8 !important;
    display: flex !important;
    gap: 0.25rem !important;
    flex-direction: wrap !important;
}

.editIconContainer {
    display: flex;
    gap: 1rem;
}

.paperContainer {
    padding: 0.75rem;
    border-radius: 0.75rem;
    height: 100%;
    overflow-y: auto;
}

.flexContainer {
    display: flex;
    align-items: end;
}

.justifySpaceBetween {
    justify-content: space-between !important;
}

.justifyEnd {
    justify-content: end !important;
}

.justifyCenter {
    justify-content: center !important;
}

.noDataAvailableText {
    font-weight: 700 !important;
    color: #8c1ec8;
}

.emailLink {
    color: #8c1ec8 !important;
    text-decoration: none !important;
}