.folderListItemContainer {
    padding: 0;
    flex-wrap: nowrap;
}

.flexContainer {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.listItemContainer {
    padding: 0;
    padding-left: 0.5rem;
}