.stackFormWrapper {
    width: 100%;
    /* min-width: 400px; */
    gap: 1.5rem;
    padding: 0.5rem;
}

.dialogActions {
    padding: 0.75rem;
    justify-content: space-between;
}

.dialogTitle {
    text-align: center;
}

.dialogContent {
    overflow-y: visible;
}

.paperProps {
    max-height: 95%;
    width: 30vw;

}

.dropDownSubjectSearch {
    width: 100% !important;
}