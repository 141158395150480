.flexContainer {
    display: flex;
    gap: 1rem;
}

.buttonContainer {
    display: flex;
    justify-content: space-between;
}

.noMargin {
    margin: 0;
}

.uploadButton {
    float: right !important;
    padding: 0 !important;
    display: flex !important;
    justify-content: end !important;
    min-width: 0 !important;
}

.generalListItemWrapper__sendEmailBtn {
    background-color: #ff6428 !important;
    color: #fff !important;
    margin: 0.625rem 0 !important;
}

.generalListItemWrapper__uploadBtn {
    background-color: #dc3545 !important;
    color: #fff !important;
    margin: 0.625rem 0 !important;
}