.container {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.noPadding {
    padding: 0;
}

.displayBlock {
    display: block !important;
}

.typoUnderlined {
    display: block !important;
    text-decoration: underline !important;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 2rem;
}

.iconContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.emailLink {
    text-decoration: none;
}

.heading,
.secondaryText {
    text-align: center;
}

.tableContainer {
    width: 80%;
    padding: 1rem;
}

.table {
    width: 100%;
    border: 0.5px solid black;
    border-radius: 0.75rem;
    padding: 1rem;
    border-collapse: inherit;
    margin-bottom: 2rem;
}

.tableCol1 {
    width: 20%;
}

.tableCol2 {
    width: 80%;
}

.tableHeader {
    font-weight: 700;
    padding: 0.75rem;
}

.tableData {
    padding: 0.75rem;
}

.tableDescription {
    font-weight: 700;
    padding: 0.75rem;
    position: absolute;
}

.button {
    width: 100% !important;
    background-color: #8c1ec8 !important;
    font-weight: 700 !important;
    margin-bottom: 2rem !important;
}