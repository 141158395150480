.container {
    padding: 1rem !important;
    background-color: #fff;
    border-radius: 0.75rem;
    width: 100%;
    display: block;
}

.subContainer {
    display: flex;
    flex-direction: column;
    align-items: start;
}

.personInfo {
    flex: 0 1 auto;
    width: 100%;
}

.companyInfo,
.examinationInfo {
    flex: 0 1 auto;
}

.flexContainer {
    display: flex;
    justify-content: space-between;
}

.link {
    color: #8c1ec8;
    cursor: pointer;
    text-decoration: none;
}

.primaryText {
    font-weight: 700 !important;
    font-size: 1rem !important;
    color: #000000 !important;
}

.secondaryText {
    font-size: 0.875rem !important;
    line-height: 1.43 !important;
    font-weight: 400 !important;
    color: rgba(0, 0, 0, 0.6) !important;
}