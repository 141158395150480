.serviceLevelChip {
    background-color: #008c7d !important;
    color: #fff !important;
}

.mainContainer {
    gap: 1rem;
}

.title {
    margin: 0;
}

.buttonContainer {
    display: flex;
    align-items: end;
    justify-content: space-between;
}

.container1 {
    height: 25vh;
}

.fullHeight {
    height: 100%;
}