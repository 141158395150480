.permissionWrapper {
  /* margin-left: 30vw; */
  /* margin-right: auto; */
  /* margin: 0 auto; */
  /* width: 100%; */
  /* max-width: 50vw; */
  margin-bottom: 1rem;
}

.permissionTable {
  margin-top: 1rem;
  border-collapse: collapse;
  width: 100%;
}

.permissionTable td {
  border: 1px solid #ddd;
  padding: 8px;
}

.permissionTable tr:nth-child(even) {
  background-color: #f2f2f2;
}

.permissionTable tr:hover {
  background-color: #ddd;
}

.permissionTable th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: var(--humanFirst);
  color: white;
  border: 1px solid #ddd;
  padding: 8px;
}

.permissionsContainer {
  flex-direction: column;
  max-width: 1000px;
  margin: 0 auto;
}

.buttonsContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}