.listItem {
    display: flex !important;
    font-size: 1rem !important;
    padding: 0.5rem 0 0.5rem 0.5rem !important;
    width: 100% !important;
    align-items: inherit !important;
}

.listItemNoAlign {
    display: flex !important;
    font-size: 1rem !important;
    padding: 0.5rem 0 0.5rem 0.5rem !important;
    width: 100% !important;
}

.listItemIcon {
    min-width: auto !important;
    margin-right: 0.5rem !important;
    margin-top: 0.375rem !important;
}

.listItemIconNoMarginTop {
    min-width: auto !important;
    margin-right: 0.5rem !important;
}

.linearProgressContainer {
    padding: 0 0.5rem;
}

.linearProgress {
    background-color: #f4f2f2 !important;
    height: 0.5rem !important;
    border-radius: 1.25rem !important;
}

.linearProgressText {
    float: right !important;
    padding: 0.5rem !important;
}

.displayInline {
    display: inline !important;
}

.displayBlock {
    display: block !important;
}