.listItem {
    display: flex !important;
    font-size: 1rem !important;
    padding: 0.5rem 0 0.5rem 0.5rem !important;
    width: 100% !important;
}

.listItemIcon {
    justify-content: center;
}

.container {
    margin-top: 1rem !important;
}