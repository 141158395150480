.container {
    width: 100% !important;
}

.fileListWrapper {
    margin-top: 1rem !important;
}

.heading {
    padding: 0 !important;
}