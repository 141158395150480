.container {
    padding: 1rem !important;
    background-color: #fff;
    border-radius: 0.75rem;
    width: 100%;
}

.link {
    color: #8c1ec8;
    cursor: pointer;
    text-decoration: none;
}

.emailLink {
    color: #8c1ec8;
    text-decoration: none;
}

.primaryText {
    font-size: 0.875rem !important;
    line-height: 1.43 !important;
    font-weight: 400 !important;
    color: rgba(0, 0, 0, 0.6) !important;
}

.secondaryText {
    font-weight: 700 !important;
    font-size: 1rem !important;
    color: #000000 !important;
}

.secondaryTextNoBold {
    font-weight: 400 !important;
    font-size: 1rem !important;
    color: #000000 !important;
}