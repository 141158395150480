.timeSlotsWrapper {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    border-radius: 1rem !important;
    
}

.fullHeight {
    height: 100% !important;
}