.container {
    max-width: 65vw !important;
    margin: 0 auto !important;
}

.spinnerContainer {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    align-items: center;
    justify-content: center;
    z-index: 100000;
}

.slotButtonsContainer {
    display: flex;
    gap: 1rem;
}

.buttonStyle {
    border-radius: 0.75rem;
    text-transform: none;
}

.dateTimePicker {
    width: 100%;
}

.textField {
    width: 100%;
}

.navigateBackButton {
    align-self: flex-start;
}

.generalListItemWrapper {
    text-align: center !important;
    justify-content: center !important;
}

.generalListItemWrapper__selectedDay {
    background-color: #66bab1 !important;
    color: #fff !important;
}

.generalListItemWrapper__noSelectedDay {
    background-color: #f4f2f2 !important;
    color: unset !important;
}