.button {
    text-transform: none !important;
    padding: 0.75rem !important;
    border-radius: 0.75rem !important;
}

.card {
    padding: 1rem;
    background-color: #f4f2f2 !important;
    border-radius: 0.75rem;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.cardTitle {
    font-weight: 700 !important;
}

.cardButton {
    background-color: #8c1ec8 !important;
}

.buttonsWrapper {
    display: flex;
    gap: 1rem;
    align-items: center;
}