.appBarContainer {
  background-color: transparent !important;
  box-shadow: none !important;
}

.toolbarContainer {
  background-size: cover !important;
  background-color: transparent !important;
  padding: 1rem 0 !important;
}

.menuButton {
  margin: 0 1rem !important;
  display: flex !important;
  align-items: center !important;
}

.headerBar {
  height: 5rem;
  align-items: center;
  justify-content: space-between;
}

.wrapper {
  background-color: var(--warmGrey30);
}

.headerBarButtonItemContainer {
  padding-right: 20px;
}

.headerBarButtonGrid {
  align-items: center;
  justify-content: center;
}

.headerBarButtonGridItem {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5rem;
}

.headerBarUserIcon {
  background-color: var(--humanFirst) !important;
}

.headerBarAvatar {
  background-color: var(--humanFirst) !important;
  color: var(--white) !important;
  font-weight: bold;
}

.headerBarMenuUserName {
  font-weight: bold;
}

.headerBarMenuUserMail {
  font-size: 0.85rem;
}

/* Media Query */
/* For mobile phones: */
@media (max-width: 600px) {
  .menuButton {
    margin: 0 !important;
  }
}