.container {
    display: flex;
    flex-direction: column;
}

.dropdownWrapper {
    display: flex;
    gap: 10px;
    align-items: flex-end;
    width: 80%;
}

.buttonWrapper {
    display: flex;
    margin-top: 10px;
    justify-content: space-between;
    align-items: center;
}

.lastSaved {
    display: flex;
    flex-direction: column;
    font-size: 0.75rem;
}

.dropDown__templates {
    position: relative !important;
    margin-top: 1rem;
}

.dropDown__selectedTemplate {
    z-index: 10 !important;
}

.textArea__field {
    margin-top: 1rem;
}