.loaderWrapper {
    display: flex;
    justify-content: center;
}

.selectWrapper {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    width: 100%;
}

.dropdownWithRefLabel {
    display: flex;
    margin-bottom: 0.25rem;
}

.labelRequired {
    color: red;
}