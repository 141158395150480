.selectWrapper {
    display: inline-flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px;
}

.datePicker,
.dateTimePicker {
    width: 100%;
}

.dropDownSubjectSearch {
    z-index: 10 !important;
    overflow-y: visible !important;
}