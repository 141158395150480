.errorWrapper {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    margin: 0.25rem 0;
}

.errorSpan {
    font-size: 0.8rem;
    color: #f44336; /* Corresponds to Material-UI 'error' color */
}