.tableHeaderCell {
    width: 150px;
    padding: 10px;
}

.tableCell {
    padding: 10px;
}

.statusCell {
    padding: 10px;
    font-weight: bold;
}