.stackFormWrapper {
    width: 100%;
    min-width: 300px;
    gap: 1.5rem;
    padding: 0.5rem;
}

@media (min-width: 600px) {
    .stackFormWrapper {
        min-width: 360px;
    }
}

@media (min-width: 900px) {
    .stackFormWrapper {
        min-width: 400px;
    }
}