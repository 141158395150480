.headerBox {
    margin-bottom: 1rem;
}

.gridContainer {
  gap: 1rem;
}

.leftColumn {
    row-gap: 1rem;
}

.paperContainer {
    padding: 1rem;
    margin-top: 1rem;
    box-shadow: none;
}

.paperTitle {
    font-size: 1.25rem;
}