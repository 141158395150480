.container {
    width: 50em !important;
    align-content: start !important;
    margin-left: auto !important;
    margin-right: auto !important;
}

.heading {
    display: flex;
    flex-direction: column;
}

.iconContainer {
    display: flex;
    gap: 1rem;
}

.linkNoUnderline {
    color: #8c1ec8;
    text-decoration: none;
}

.linkUnderlined {
    color: #8c1ec8;
    text-decoration: underline;
}