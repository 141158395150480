.form {
    margin: 10px;
    width: 50vw;
}

.dateTimePickerWrapper {
    width: 100%;
    margin-top: 1rem;
}

.dateTimePicker {
    width: 100%;
}

.tableWrapper {
    margin-top: 1rem;
}

.tableRowActions {
    display: flex;
    gap: 1rem;
}

.tableTopToolbar {
    display: flex;
    gap: 0.5rem;
}

.buttonWrapper {
    display: flex;
    margin-top: 10px;
    justify-content: space-evenly;
}

.dropDown__appointmentStatus {
    position: relative !important;
    z-index: 11 !important;
}

.dropDown__team,
.dropDown__executedBy,
.dropDownSubjectSearch {
    position: relative !important;
    z-index: 10 !important;
}