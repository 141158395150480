/* Login Styles */
.heading {
  color: var(--text1);
}
.heading_Mb{
  margin-bottom: 1rem;
}
.alignSelf{
  align-self: center;
}
.textAlignCenter{
  text-align: center;
}
.flexColumn{ 
  display: flex; 
  flex-direction: column;
}
.justifyContent{
  justify-content: center;
}
.alignItems{
  align-items: center;
}
.login {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
}
.login_form {
  display: flex;
  flex-direction: column;
}

.login_formLabel {
  display: flex;
  flex-direction: column;
  color: var(--text1);
  margin: 1rem 0;
}
.login_formInput {
  border-radius: 0.5rem;
  border: 0;
  outline: none;
  padding: 0.75rem;
}

.login_passwordReminderWrapper {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}
.login_passwordReminderLink {
  color: var(--text1);
  font-weight: bold;
  align-self: center;
}
.login_submitButton {
  background: var(--background2) !important;
  /* margin-top: 3rem !important; */
  padding: 0.75rem !important;
  border-radius: 1rem !important;
  width: 100% !important;
}

/* ---- passwordReset styles */
.passwordRestMessage {
  color: var(--text1);
  font-size: 1.25rem;
}
.passwordResetInput {
  border-radius: 0.5rem;
  border: 0;
  outline: none;
  padding: 0.75rem;
}

/* .formWrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
} */
/* .passwordReset_formWrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
} */

.emailForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem auto 0;
}
.passwordForm {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.passwordReset_cancelButton {
  background: var(--background4) !important;
  /* margin-top: 3rem !important; */
  padding: 0.75rem !important;
  border-radius: 1rem !important;
  min-width: 40% !important;
}

.passwordReset_emailSubmitButton {
  background: var(--background2) !important;
  /* margin-top: 3rem !important; */
  padding: 0.75rem !important;
  border-radius: 1rem !important;
  min-width: 40% !important;
}

.passwordReset_submitButton {
  background: var(--background2) !important;
  /* margin-top: 3rem !important; */
  padding: 0.75rem !important;
  border-radius: 1rem !important;
  width: 100% !important;
}

/* ---- Register styles */

.privacyPolicyLink {
  color: var(--text1);
  font-weight: bold;
}
.privacyPolicyLink:hover {
  color: var(--background2);
}
.register_submitButton {
  background: var(--background2) !important;
  /* margin-top: 3rem !important; */
  padding: 0.75rem !important;
  border-radius: 1rem !important;
  width: 100% !important;
}
.registerForm {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.registerFormWrapper {
  /* position: absolute; */
  /* position: relative;
  left: 50%;
  top: 53%;
  transform: translate(-50%, -50%); */
  width: 50%;
}

.checkboxWrapper{
  margin: 1rem 0;

}

.successNotification{
  color: var(--text1);
  text-align: center;
}

.register_navigateButton {
  background: var(--background2) !important;
  padding: 0.75rem !important;
  border-radius: 1rem !important;
  width: 50% !important;
}
