.allDoneContainer {
  width: 100%;
  padding: 1.5rem;
  border-radius: 1rem !important;
  margin-top: 1rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column !important;
}

.allDoneHeading {
  margin-bottom: 2rem;
}

.allDoneContentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.allDoneContentHeading {
  text-align: center;
}

.todoItem {
  width: 100%;
}

.todoItemContentWrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.todoCheckboxRound {
  position: relative;
}

.todoCheckboxRound label {
  background-color: #fff;
  border: 1px solid var(--newWork80);
  border-radius: 50%;
  /* cursor: pointer; */
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 28px;
  background-color: #EADFF0;

}

.todoCheckboxRound label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}

.todoCheckboxRound input[type="checkbox"] {
  visibility: hidden;
}

.todoCheckboxRound input[type="checkbox"]:checked+label {
  background-color: var(--newWork80);
  border-color: var(--newWork80);
}

.todoCheckboxRound input[type="checkbox"]:checked+label:after {
  opacity: 1;
}

.listItemTextWrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.remainingDaysIconWrapper {
  margin-right: 0.5rem;
}

.todoContainerHeading {
  margin: 1rem 0;
}

.todoListItemText {
  margin-top: 1rem !important;
}

.todoListItem {
  width: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
}

.todoItemDescription {
  margin: 0 !important;
  text-align: justify !important;
}

.remainingDays {
  background-color: #f7d1c0;
  padding: 0.5rem;
  border-radius: 0.5rem;
  font-size: 0.875rem !important;
}

.generalListItemWrapper {
  margin: 0 !important;
  padding: 0.75rem 1.5rem !important;
}


/*  Media Queries */
/* For mobile phones: */
@media (max-width: 600px) {
  .todosHeader {
    padding-top: 1.25rem !important;
    padding-left: 0.5rem !important;
  }

  .todoContainerHeading {
    padding-left: 0.5rem !important;
  }

  .todoCheckboxRound {
    margin-left: 0.5rem;
  }

  .todoItemDescription {
    color: rgba(0, 0, 0, 0.5);
  }

  .generalListItemWrapper {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .listItemTextWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    gap: 1.5rem;
  }

  .todoListItemText {
    width: 100%;
  }
}