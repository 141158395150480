.acceptedFileInfo {
    /* margin: 0.5rem 0; */
    font-size: 0.75rem;
    display: flex;
    flex-direction: column;
    font-weight: bold;
}

.fileUploadContainer {
    width: 100%;
}

.fileUploadInput {
    gap: 1rem; /* equivalent to gap={2} in MUI */
    display: flex;
    align-items: center;
}

.fileUploadButton {
    text-transform: none;
}

.selectedFileName {
    font-weight: bold;
    word-break: break-all !important;
}

.buttonContainer {
    display: flex;
    justify-content: space-evenly;
    /* Default justify content */
}

.buttonContainer.justifyContent {
    justify-content: var(--justify-content);
}